@charset "utf-8";
/* Noto Sans */
@font-face {
  font-family: 'Noto Sans';
  font-weight: 100;
  font-style: normal;
  src: local('Noto Sans Thin'), local('Noto Sans KR Thin'),
    local('Noto Sans CJK Thin');
  src: url('NotoSans/NotoSans-Thin.eot');
  src: url('NotoSans/NotoSans-Thin.eot?#iefix') format('embedded-opentype'),
    url('NotoSans/NotoSans-Thin.woff2') format('woff2'),
    url('NotoSans/NotoSans-Thin.woff') format('woff'),
    url('NotoSans/NotoSans-Thin.otf') format('truetype');
}
/* @font-face {
  font-family: 'Noto Sans';
  font-weight: 200;
  font-style: normal;
  src: local('Noto Sans Demi Light'), local('Noto Sans KR DemiLight'),
    local('Noto Sans CJK DemiLight');
  src: url('NotoSans/NotoSans-DemiLight.eot');
  src: url('NotoSans/NotoSans-DemiLight.eot?#iefix') format('embedded-opentype'),
    url('NotoSans/NotoSans-DemiLight.woff2') format('woff2'),
    url('NotoSans/NotoSans-DemiLight.woff') format('woff'),
    url('NotoSans/NotoSans-DemiLight.otf') format('truetype');
} */
@font-face {
  font-family: 'Noto Sans';
  font-weight: 300;
  font-style: normal;
  src: local('Noto Sans Light'), local('Noto Sans KR Light'),
    local('Noto Sans CJK Light');
  src: url('NotoSans/NotoSans-Light.eot');
  src: url('NotoSans/NotoSans-Light.eot?#iefix') format('embedded-opentype'),
    url('NotoSans/NotoSans-Light.woff2') format('woff2'),
    url('NotoSans/NotoSans-Light.woff') format('woff'),
    url('NotoSans/NotoSans-Light.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-weight: 400;
  font-style: normal;
  src: local('Noto Sans Regular'), local('Noto Sans KR Regular'),
    local('Noto Sans CJK Regular');
  src: url('NotoSans/NotoSans-Regular.eot');
  src: url('NotoSans/NotoSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('NotoSans/NotoSans-Regular.woff2') format('woff2'),
    url('NotoSans/NotoSans-Regular.woff') format('woff'),
    url('NotoSans/NotoSans-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-weight: 500;
  font-style: normal;
  src: local('Noto Sans Medium'), local('Noto Sans KR Medium'),
    local('Noto Sans CJK Medium');
  src: url('NotoSans/NotoSans-Medium.eot');
  src: url('NotoSans/NotoSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('NotoSans/NotoSans-Medium.woff2') format('woff2'),
    url('NotoSans/NotoSans-Medium.woff') format('woff'),
    url('NotoSans/NotoSans-Medium.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-weight: 600;
  font-style: normal;
  src: local('Noto Sans Bold'), local('Noto Sans KR Bold'),
    local('Noto Sans CJK Bold');
  src: url('NotoSans/NotoSans-Bold.eot');
  src: url('NotoSans/NotoSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('NotoSans/NotoSans-Bold.woff2') format('woff2'),
    url('NotoSans/NotoSans-Bold.woff') format('woff'),
    url('NotoSans/NotoSans-Bold.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-weight: 700;
  font-style: normal;
  src: local('Noto Sans Bold'), local('Noto Sans KR Bold'),
    local('Noto Sans CJK Bold');
  src: url('NotoSans/NotoSans-Bold.eot');
  src: url('NotoSans/NotoSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('NotoSans/NotoSans-Bold.woff2') format('woff2'),
    url('NotoSans/NotoSans-Bold.woff') format('woff'),
    url('NotoSans/NotoSans-Bold.otf') format('truetype');
}
/* @font-face {
  font-family: 'Noto Sans';
  font-weight: 900;
  font-style: normal;
  src: local('Noto Sans Black'), local('Noto Sans KR Black'),
    local('Noto Sans CJK Black');
  src: url('NotoSans/NotoSans-Black.eot');
  src: url('NotoSans/NotoSans-Black.eot?#iefix') format('embedded-opentype'),
    url('NotoSans/NotoSans-Black.woff2') format('woff2'),
    url('NotoSans/NotoSans-Black.woff') format('woff'),
    url('NotoSans/NotoSans-Black.otf') format('truetype');
} */

/* Poppins */
@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  font-style: normal;
  src: local('Poppins');
  src: url('Poppins/Poppins-Thin.woff') format('woff'),
    url('Poppins/Poppins-Thin.otf') format('otf'),
    url('Poppins/Poppins-Thin.ttf') format('truetype');
}
/* @font-face {
  font-family: 'Poppins';
  font-weight: 200;
  font-style: normal;
  src: local('Poppins');
  src: url('Poppins/Poppins-ExtraLight.woff') format('woff'),
    url('Poppins/Poppins-ExtraLight.otf') format('otf'),
    url('Poppins/Poppins-ExtraLight.ttf') format('truetype');
} */
@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: normal;
  src: local('Poppins');
  src: url('Poppins/Poppins-Light.woff') format('woff'),
    url('Poppins/Poppins-Light.otf') format('otf'),
    url('Poppins/Poppins-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  src: local('Poppins');
  src: url('Poppins/Poppins-Regular.woff') format('woff'),
    url('Poppins/Poppins-Regular.otf') format('otf'),
    url('Poppins/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  src: local('Poppins');
  src: url('Poppins/Poppins-Medium.otf') format('otf'),
    url('Poppins/Poppins-Medium.woff') format('woff'),
    url('Poppins/Poppins-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  src: local('Poppins');
  src: url('Poppins/Poppins-SemiBold.woff') format('woff'),
    url('Poppins/Poppins-SemiBold.otf') format('otf'),
    url('Poppins/Poppins-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: normal;
  src: local('Poppins');
  src: url('Poppins/Poppins-Bold.woff') format('woff'),
    url('Poppins/Poppins-Bold.otf') format('otf'),
    url('Poppins/Poppins-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  font-style: normal;
  src: local('Poppins');
  src: url('Poppins/Poppins-ExtraBold.woff') format('woff'),
    url('Poppins/Poppins-ExtraBold.otf') format('otf'),
    url('Poppins/Poppins-ExtraBold.ttf') format('truetype');
}
/* @font-face {
  font-family: 'Poppins';
  font-weight: 900;
  font-style: normal;
  src: local('Poppins');
  src: url('Poppins/Poppins-Black.woff') format('woff'),
    url('Poppins/Poppins-Black.otf') format('otf'),
    url('Poppins/Poppins-Black.ttf') format('truetype');
} */

/* AppleGothic */
@font-face {
  font-family: 'AppleGothicNeoSD';
  font-weight: 400;
  font-style: normal;
  src: local('AppleGothic'), local('AppleSDGothicNeo'),
    local('AppleGothicNeoSD'), local('Apple SD 산돌고딕 Neo');
  src: url('AppleGothic/AppleSDGothicNeoMedium.otf') format('otf');
}
@font-face {
  font-family: 'AppleGothicNeoSD';
  font-weight: normal;
  font-style: normal;
  src: local('AppleGothic'), local('AppleSDGothicNeo'),
    local('AppleGothicNeoSD'), local('Apple SD 산돌고딕 Neo');
  src: url('AppleGothic/AppleSDGothicNeoMedium.otf') format('otf');
}
@font-face {
  font-family: 'AppleGothicNeoSD';
  font-weight: 500;
  font-style: normal;
  src: local('AppleGothic'), local('AppleSDGothicNeo'),
    local('AppleGothicNeoSD'), local('Apple SD 산돌고딕 Neo');
  src: url('AppleGothic/AppleSDGothicNeoSemiBold.otf') format('otf');
}
@font-face {
  font-family: 'AppleGothicNeoSD';
  font-weight: 600;
  font-style: normal;
  src: local('AppleGothic'), local('AppleSDGothicNeo'),
    local('AppleGothicNeoSD'), local('Apple SD 산돌고딕 Neo');
  src: url('AppleGothic/AppleSDGothicNeoSemiBold.otf') format('otf');
}
@font-face {
  font-family: 'AppleGothicNeoSD';
  font-weight: 700;
  font-style: normal;
  src: local('AppleGothic'), local('AppleSDGothicNeo'),
    local('AppleGothicNeoSD'), local('Apple SD 산돌고딕 Neo');
  src: url('AppleGothic/AppleSDGothicNeoBold.otf') format('otf');
}
@font-face {
  font-family: 'AppleGothicNeoSD';
  font-weight: bold;
  font-style: normal;
  src: local('AppleGothic'), local('AppleSDGothicNeo'),
    local('AppleGothicNeoSD'), local('Apple SD 산돌고딕 Neo');
  src: url('AppleGothic/AppleSDGothicNeoBold.otf') format('otf');
}

